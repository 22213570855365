<template lang="pug">
    section.content
        div.container-fluid
            div.row
                div.col-md-12
                    div.card
                        div.card-header
                            h3.card-title {{ $t('detailresults.detailedresults') }}
                        div.card-body
                            div.table-responsive
                                table.table.table-bordered.table-hover.mb-4
                                    thead
                                        tr
                                            th {{ $t('detailresults.theme') }}
                                            th {{ $t('detailresults.questions') }}
                                            th {{ $t('detailresults.correct') }}
                                            th {{ $t('detailresults.percentage') }}
                                    tbody
                                        tr(v-for="detail in detailedReport")
                                            td {{detail.theme}}
                                            td {{detail.total}}
                                            td {{ detail.corrects }}
                                            td {{ detail.prom }}%
                            button.btn.btn-sm.btn-info(type="button" @click="$router.push('/student/MyExams/')") {{ $t('Results.return') }}

</template>

<script>
/* eslint-disable */
export default {
    name: 'DetailResults',
    props: ['idPersonExam', 'idGroupDetail'],
    components: {
    },
    data() {
        return {
            detailedReport: []
        }
    },
    methods: {
        async getData(){
                await axios.get(apiURL + 'Student/getDetailedReport?idPersonExam='+ this.idPersonExam + '&idGroupDetail=' + this.idGroupDetail, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.detailedReport = response.data.detailedReport
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        }


    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)
        this.getData()
    }
}
</script>
